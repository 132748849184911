import {AccountRegContactType} from '../../shared/users/user.types';
import {UpdateListActionTypes} from '../../shared/lists/list.class';

export type AnonymousPriceVisibility = undefined | `No` | `Yes`;
type GaLegacyEvent =
    | `Error`
    | `fileImportStep1`
    | `fileImportStep2`
    | `gaEvent`
    | `injectQualtricsScript`
    | `injectQualtricsScriptIDP`
    | `injectQualtricsScriptIntercepts`
    | `site_search`;
type GaLegacySiteSearchMethod = `legacy_search` | `recent_search` | `typeahead_search`;

interface GaLegacyEventOptions {
    eventAction?:
        | `${AccountRegContactType} Form Abandonment`
        | ``
        | `Add All Items to Order Clicks`
        | `Add All Items to Order`
        | `Add to Order Clicks`
        | `Add to Order Error`
        | `Add to Order Scan`
        | `add_location_to_order`
        | `add_to_order`
        | `back_in_stock_form`
        | `camera_permission_messaging_reload_button`
        | `Category On`
        | `checkboxes`
        | `Clear All Recent Search Terms`
        | `Clear Single Recent Search Term`
        | `click`
        | `Create Account`
        | `Create New Order`
        | `create_new_order`
        | `Credit App Form Abandonment`
        | `Credit Application Error`
        | `Credit Application Success`
        | `Cross-Reference Switch Off`
        | `Cross-Reference Switch On`
        | `delete list`
        | `Download Order`
        | `email`
        | `Error`
        | `Filter Button Menu Click`
        | `Form Abandonment`
        | `Form Submit Error`
        | `Form Submit Success`
        | `group_item_by_shipment_switch`
        | `Guided Navigation Facet Off`
        | `Guided Navigation Facet On`
        | `hamburger_menu`
        | `header`
        | `IDP Email Button Click`
        | `image_magnify`
        | `Invalid Items`
        | `Invoice Click`
        | `Jump To Click`
        | `List Facet Off`
        | `List Facet On`
        | `Next Click`
        | `null_search_custom_catalog_button`
        | `null_search_phone_number_click`
        | `null_search_send_email`
        | `null_search_start_chat`
        | `order copy false`
        | `order copy true`
        | `orders-page-delete-order`
        | `orders-page-view-order`
        | `orders_menu_create_new_order`
        | `orders_menu_select_order`
        | `orders_menu_view_order`
        | `Pagination`
        | `Previous Click`
        | `product_family_circle`
        | `product_family_dropdown`
        | `product_family_square`
        | `product_family_tiles`
        | `punchout_resubmit_confirmation_modal_cancel`
        | `punchout_resubmit_confirmation_modal_submit`
        | `qty_tiles`
        | `Recent Search Term`
        | `remove_items`
        | `Request Pending`
        | `Request Success`
        | `save for later`
        | `scanner_reload`
        | `Search`
        | `settings`
        | `Share Items`
        | `shipping confirmation false`
        | `shipping confirmation true`
        | `show_select`
        | `sort by`
        | `Sort By`
        | `sort_by`
        | `Step 1: Validation Error`
        | `Step 2: Validation Error`
        | `Step 3: Validation Error`
        | `Step 4: Validation Error`
        | `Submission Error`
        | `Tile Facet Off`
        | `Tile Facet On`
        | `update_information`
        | `verify_information`
        | `view_all_orders`
        | `Visual Attribute Facet Off`
        | `Visual Attribute Facet On`
        | UpdateListActionTypes;
    // Also `${name}`, `generate_barcode_${itemNum}`, `quick_add_${itemNum}`, `quick_add_${itemToAdd}`, `remove_${itemNum}`
    // Also `Wiper Blades | ${window.location.pathname}`, `${this.inputElementID}`, `${gaReference} add`, `${componentName}`
    eventCategory?:
        | `account_management`
        | `Apply Coupon`
        | `back to top`
        | `barcode_scanner_ro`
        | `barcode_scanner`
        | `Browse`
        | `Campaign Landing Page`
        | `Category Navigation`
        | `checkout`
        | `Create Quote Request`
        | `Credit Application Form`
        | `Ecommerce`
        | `Email Item`
        | `Faceted Search`
        | `Guided Navigation`
        | `IDP`
        | `Legacy Search Results Page`
        | `list`
        | `lists`
        | `My Shop Location Page`
        | `my-shop`
        | `my_shop_detail`
        | `my_shop`
        | `online-returns`
        | `Open Orders`
        | `Order History Items`
        | `Order History`
        | `Order Summary Page`
        | `Order Summary`
        | `Print Page`
        | `Request Account`
        | `Search Field`
        | `Search Overlay`
        | `search_field`
        | `Vehicle Selector`
        | `WonderSpinner Navigation`;
    // Also `${componentName}`, `${gaListName}`, `${listName}`, `${subComponentName}`, `Download - ${orderNumber || invoiceNumber}`
    // Also `${sendEmailParams.ga_category}`, `Lists: ${listTitle}`, `${this.tableElementID}`, `${$(this).attr('data-list')} Landing Page`
    eventLabel?: string;
    eventValue?: null;
    had_results?: boolean;
    non_interaction?: false;
    search_method?: GaLegacySiteSearchMethod;
    search_term?: string;
}

/**
 * Pushes legacy custom event to the dataLayer
 * @param gaLegacyCustomEventOptions
 */
export const gaLegacyCustomEvent = (gaLegacyCustomEventOptions: GaLegacyEventOptions) => {
    gaLegacyDataLayerPush(`gaEvent`, gaLegacyCustomEventOptions);
};

/**
 * Pushes provided legacyGaEvent to the dataLayer
 * @param legacyGaEvent
 * @param legacyGaEventOptions
 */
export const gaLegacyDataLayerPush = (legacyGaEvent: GaLegacyEvent, legacyGaEventOptions?: GaLegacyEventOptions) => {
    dataLayer.push({event: legacyGaEvent, ...legacyGaEventOptions});
};

export const gaLegacySiteSearch = (search_term: string, search_method: GaLegacySiteSearchMethod, had_results: boolean) => {
    gaLegacyDataLayerPush(`site_search`, {had_results, search_method, search_term});
};
